import React, { useEffect, useState } from "react";
import "../../CSS/CustomCSS.css";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";
import { Container } from "react-bootstrap";
import axios from "axios";
import Cards from "./Cards";
import { useNavigate } from "react-router-dom";
import PinModal from "../Revenue/PinModal";

function Index() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPinVerified, setIsPinVerified] = useState(false);
  const navigate = useNavigate();
  console.log("Testing text");
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1024);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const checkSession = () => {
      const authKey = localStorage.getItem("authKey");
      const authKeyExpiry = localStorage.getItem("authKeyExpiry");
      if (
        !authKey ||
        !authKeyExpiry ||
        Date.now() > parseInt(authKeyExpiry, 10)
      ) {
        localStorage.removeItem("authKey");
        localStorage.removeItem("authKeyExpiry");
        navigate("/");
      }
    };
    checkSession();
  }, [navigate]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          "https://thedynamicgym.in/api/members"
        );
        setMembers(response.data);
      } catch (error) {
        console.error("Error fetching members:", error);
        setError("Failed to fetch members. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handlePinSubmit = async (enteredPin) => {
    try {
      console.log("Sending PIN to API:", enteredPin);

      if (enteredPin === "3939") {
        setIsPinVerified(true);
      } else {
        alert("Incorrect PIN. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying PIN:", error);
      alert(
        "An error occurred while verifying the PIN. Please try again later."
      );
    }
  };
  return (
    <div>
      <PinModal show={!isPinVerified} onSubmit={handlePinSubmit} />
      <Container className="ContWidth P0">
        {!isSmallScreen && (
          <div className="Navouter">
            <LeftComponent />
          </div>
        )}
        <div className="Mainouter">
          {isPinVerified && <RightComponent members={members} />}
        </div>
      </Container>
    </div>
  );
}

export default Index;
