import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

function Filters({ selectedYear, selectedMonth, onFilterChange }) {
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    onFilterChange(year, null);
  };

  const handleMonthChange = (event) => {
    const month = parseInt(event.target.value, 10);
    onFilterChange(selectedYear, month);
  };

  return (
    <div>
      <Row>
        <Col md={{ span: 3, offset: 6 }} sm={12}>
          <Form.Select
            value={selectedYear || ""}
            onChange={handleYearChange}
            className="filters"
          >
            <option value="">Select Year</option>
            {[2024, 2025, 2026, 2027, 2028, 2029, 2030].map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={3} sm={12}>
          <Form.Select
            value={selectedMonth || ""}
            onChange={handleMonthChange}
            className="filters"
            disabled={!selectedYear}
          >
            <option value="">Select Month</option>
            {[
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ].map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </div>
  );
}

export default Filters;
