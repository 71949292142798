import React, { useState } from "react";
import { getRenewalMessage, formatTime } from "../../utils/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../CSS/CustomCSS.css";
import { Stack } from "@mui/material";
const MySwal = withReactContent(Swal);

const ResultCard = ({ data }) => {
  const [loading, setLoading] = useState(false);

  if (!data) {
    return <p className="norecord">No Record found</p>;
  }

  const {
    MID,
    Name,
    Number,
    Date_of_Join,
    MembershipDetails,
    Attendance,
    ImagePath,
  } = data;

  const renewalMessage = getRenewalMessage(MembershipDetails["Renewal Date"]);

  const showModal = (message) => {
    MySwal.fire({
      title: message,
      icon: "success",
      showConfirmButton: false,
      timer: 3000, // Automatically close the modal after 6 seconds
    });

    setTimeout(() => {
      window.location.reload();
    }, 3000); // Reload the page after 6 seconds
  };

  const handleStartWorkout = async () => {
    try {
      setLoading(true);
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      if (Attendance !== "absent") {
        if (Attendance.Outtime !== "00:00:00") {
          // Update existing record
          const attendanceId = Attendance.AID;

          await fetch(
            `https://thedynamicgym.in/api/attendance/${attendanceId}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ Intime: currentTime }),
            }
          );
          console.log("Updated existing record with PUT");
        }
      } else {
        // No record for today, create new
        const today = new Date().toISOString().split("T")[0];
        await fetch(`https://thedynamicgym.in/api/attendance`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            MID,
            Date: today,
            Intime: currentTime,
            Present: "Y",
          }),
        });
        console.log("Created new record with POST");
      }

      showModal("IT'S EXERCISE TIME");
    } catch (error) {
      console.error("Error handling workout:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEndWorkout = async () => {
    try {
      setLoading(true);
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const response = await fetch(
        `https://thedynamicgym.in/api/memberdata/${MID}`
      );
      const attendanceData = await response.json();
      const attendanceId = attendanceData.Attendance.AID;

      await fetch(
        `https://thedynamicgym.in/api/attendanceout/${attendanceId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ Outtime: currentTime }),
        }
      );

      showModal("THANK YOU!!! WELL DONE");
    } catch (error) {
      console.error("Error handling workout:", error);
    } finally {
      setLoading(false);
    }
  };

  let button;
  let attendanceInfo;
  if (!Attendance || Attendance === "absent") {
    button = (
      <button
        className="stratbtn"
        onClick={handleStartWorkout}
        disabled={loading}
      >
        Start WorkOut
      </button>
    );
  } else if (Attendance.Outtime === "00:00:00") {
    button = (
      <button className="Endbtn" onClick={handleEndWorkout} disabled={loading}>
        End WorkOut
      </button>
    );
    attendanceInfo = (
      <p className="descset">
        <span className="spancls">
          Start time: <b>{formatTime(Attendance.Intime)}</b>
        </span>
      </p>
    );
  } else {
    button = (
      <button
        className="stratbtn"
        onClick={handleStartWorkout}
        disabled={loading}
      >
        Start WorkOut
      </button>
    );
  }

  return (
    <div>
      <Stack direction="row" spacing={2} className="Mt20">
        <div>
          <img
            className={ImagePath ? "homeimg" : "defaultcls"}
            src={
              ImagePath
                ? `https://thedynamicgym.in/${ImagePath}`
                : "Images/userbig.png"
            }
            alt="User Icon"
          />
        </div>
        <div className="reasultcard">
          <p className="welcomcls">
            Welcome Back <span className="userNcls">{Name}</span>
          </p>
          <p className="descset">
            <span className="spancls MR10">
              ID: <b>{MID}</b>
            </span>
            |
            <span className="spancls1">
              Membership Renew Date: <b>{MembershipDetails["Renewal Date"]}</b>
              <span className={`${renewalMessage.className} expiredago1`}>
                {renewalMessage.message}
              </span>
            </span>
          </p>
          {attendanceInfo}
        </div>
      </Stack>
      <div className="Mt20">{button}</div>
      {loading && <div className="loader"></div>}
    </div>
  );
};

export default ResultCard;
