import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

function PinModal({ show, onSubmit }) {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const handlePinChange = (e) => {
    const { value } = e.target;
    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setPin(value);
      setError(""); // Clear error message when user starts typing
    } else {
      setError("PIN must be numeric");
    }
  };

  const handleSubmit = () => {
    if (pin.trim() === "") {
      setError("PIN cannot be empty");
      return;
    }
    onSubmit(pin);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Enter PIN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>PIN:</Form.Label>
          <Form.Control
            type="password"
            value={pin}
            onChange={handlePinChange}
            onKeyPress={handleKeyPress}
            isInvalid={!!error}
            pattern="\d*"
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PinModal;
