import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../CSS/CustomCSS.css";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../Home/SearchBar";
import ResultCard from "../Home/ResultCard";

function Dashboard() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
  const [data, setData] = useState(null); // Object to hold fetched data
  const [error, setError] = useState(null); // State to hold error messages
  const [loading, setLoading] = useState(false); // State to hold loading status
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const checkSession = () => {
      const authKey = localStorage.getItem("authKey");
      const authKeyExpiry = localStorage.getItem("authKeyExpiry");
      if (
        !authKey ||
        !authKeyExpiry ||
        Date.now() > parseInt(authKeyExpiry, 10)
      ) {
        localStorage.removeItem("authKey");
        localStorage.removeItem("authKeyExpiry");
        navigate("/");
      }
    };
    checkSession();
  }, [navigate]);

  const handleSearch = async (mid) => {
    try {
      setError(null); // Reset error state
      setLoading(true); // Set loading state to true
      const response = await fetch(
        `https://thedynamicgym.in/api/memberdata/${mid}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const memberData = await response.json();
      if (memberData === "Member not found") {
        setError("No Record Found");
        setData(null); // Reset data state
      } else {
        setData(memberData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("No Record Found"); // Set error message
      setData(null); // Reset data state
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div>
      <div className="Outerdiv">
        <div className="Innerdiv">
          <Container className="ContWidth">
            <Row className="P25">
              <Col xs={6} sm={6} md={6}>
                <div className="imgsetts">
                  <img src="Images/logo.png" alt="" />
                </div>
              </Col>

              <Col xs={6} md={6}>
                <div className="algndiv">
                  <a className="menubtn" href="/#/adminpage">
                    <img src="Images/menu.png" alt="" />
                  </a>
                </div>
              </Col>
            </Row>
            {isSmallScreen ? (
              <p className="gotoclss">Go to Gym</p>
            ) : (
              <Row>
                <Col md={8}>
                  <div className="DisplaDiv">
                    <h5 className="Mainheading">Train Like a Beast</h5>
                    <SearchBar onSearch={handleSearch} />
                    {loading && <div className="loader"></div>}

                    {error && <p className="error-message">{error}</p>}
                    {data && <ResultCard data={data} />}
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
