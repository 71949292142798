import React, { useState, useEffect } from "react";
import Filters from "./Filters";

function Table({ members }) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [filteredMembers, setFilteredMembers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  useEffect(() => {
    filterMembers();
  }, [members, selectedYear, selectedMonth]);

  const filterMembers = () => {
    const filtered = members.filter((member) => {
      const memberDate = new Date(member.Date);
      const memberYear = memberDate.getFullYear();
      const memberMonth = memberDate.getMonth() + 1;

      if (selectedYear && selectedMonth) {
        return memberYear === selectedYear && memberMonth === selectedMonth;
      } else if (selectedYear) {
        return memberYear === selectedYear;
      }
      return false;
    });

    setFilteredMembers(filtered);
    calculateTotalAndPercentageChange(filtered);
  };

  const calculateTotalAndPercentageChange = (filtered) => {
    const total = filtered.reduce(
      (sum, member) => sum + parseFloat(member.Amount),
      0
    );
    setTotalAmount(total);

    if (selectedMonth) {
      const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
      const previousYear =
        selectedMonth === 1 ? selectedYear - 1 : selectedYear;

      const previousFiltered = members.filter((member) => {
        const memberDate = new Date(member.Date);
        const memberYear = memberDate.getFullYear();
        const memberMonth = memberDate.getMonth() + 1;

        return memberYear === previousYear && memberMonth === previousMonth;
      });

      const previousTotal = previousFiltered.reduce(
        (sum, member) => sum + parseFloat(member.Amount),
        0
      );
      const percentage = previousTotal
        ? ((total - previousTotal) / previousTotal) * 100
        : 0;
      setPercentageChange(percentage.toFixed(2));
    } else {
      setPercentageChange(0);
    }
  };

  const handleFilterChange = (year, month) => {
    setSelectedYear(year);
    setSelectedMonth(month);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  return (
    <div>
      <div>
        <Filters
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onFilterChange={handleFilterChange}
        />
      </div>

      <div className="tableclsdiv MT20">
        <table className="tablecls">
          <thead>
            <tr className="trcls">
              <th>Amount</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="amounttd">{formatCurrency(totalAmount)}</td>
              <td
                style={{ color: percentageChange >= 0 ? "green" : "red" }}
                className="amounttd"
              >
                {percentageChange}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
