import React from "react";
import "../../CSS/CustomCSS.css";
function LeftComponent() {
  return (
    <div>
      <div className="navinner">
        <div className="logodiv">
          <img src="Images/logo.png" alt="" />
        </div>
        <div className="Linkdiv">
          <ul className="navul">
            <li>
              <a href="#/adminpage" className="Atag active">
                <img src="Images/dash.png" alt="" /> Dashboard
              </a>
            </li>
            <li>
              <a href="#/Revenue" className="Atag">
                <img src="Images/money.png" alt="" /> Revenue
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LeftComponent;
