import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { Stack, CircularProgress, Snackbar, Alert } from "@mui/material";
import Webcam from "react-webcam";
import Compressor from "compressorjs";

function AddMember({ show, handleClose }) {
  const [inputs, setInputs] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const webcamRef = useRef(null);

  const handleChanges = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (name === "Numebr") {
      value = value.slice(0, 10); // Restrict to 10 digits
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start the loader

    try {
      const formData = new FormData();

      formData.append("Names", inputs.Names);
      formData.append("Numebr", inputs.Numebr);
      formData.append("Startdate", inputs.Startdate);
      formData.append("Period", inputs.Period);
      formData.append("Amount", inputs.Amount);

      if (compressedImage) {
        formData.append("image", compressedImage, "compressedImage.jpg");
      } else if (capturedImage) {
        const response = await fetch(capturedImage);
        const blob = await response.blob();
        formData.append("image", blob, "capturedImage.jpg");
      }

      const response = await axios.post(
        "https://thedynamicgym.in/api/members",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Member added:", response.data);

      // Show snackbar
      setSnackbarOpen(true);

      // Reset fields and close modal
      setInputs({});
      setSelectedImage(null);
      setCompressedImage(null);
      setCapturedImage(null);
      handleClose();

      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error adding member:", error);
      // Handle errors, e.g., show an error message to the user
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.6, // Compression quality, 0 to 1
        success: (compressedResult) => {
          setCompressedImage(compressedResult);
          setSelectedImage(URL.createObjectURL(compressedResult));
          setCapturedImage(null);
        },
        error(err) {
          console.error("Compression failed:", err);
        },
      });
    } else {
      setSelectedImage(null);
    }
    setShowWebcam(false);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setSelectedImage(null);
    setCompressedImage(null);
    setShowWebcam(false);
  }, [webcamRef]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Check if all fields are filled and mobile number is exactly 10 digits
    const areAllFieldsFilled =
      inputs.Names &&
      inputs.Numebr &&
      inputs.Numebr.length === 10 &&
      inputs.Startdate &&
      inputs.Period &&
      inputs.Amount &&
      (compressedImage || capturedImage);
    setIsFormValid(areAllFieldsFilled);
  }, [inputs, compressedImage, capturedImage]);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="AddModalcls">
        <Modal.Header closeButton>
          <Modal.Title className="Mtitle">Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Mbody">
          <Form className="Addmemberform" onSubmit={handleSubmit}>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Enter Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="Names"
                    value={inputs.Names || ""}
                    onChange={handleChanges}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Enter Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    name="Numebr"
                    value={inputs.Numebr || ""}
                    onChange={handleChanges}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Membership Period</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="Period"
                    value={inputs.Period || ""}
                    onChange={handleChanges}
                    required
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>
                    <option value="7">7 Months</option>
                    <option value="8">8 Months</option>
                    <option value="9">9 Months</option>
                    <option value="10">10 Months</option>
                    <option value="11">11 Months</option>
                    <option value="12">1 Year</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Enter Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="Amount"
                    value={inputs.Amount || ""}
                    onChange={handleChanges}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Membership Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="Startdate"
                    value={inputs.Startdate || ""}
                    onChange={handleChanges}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Profile Picture</Form.Label>
                  <Stack direction="row" spacing={4}>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      required={!compressedImage && !capturedImage}
                    />
                    <Button
                      variant="secondary"
                      className="Cambtn"
                      onClick={() => setShowWebcam(true)}
                    >
                      <img src="Images/cam.png" alt="" />
                    </Button>
                  </Stack>
                </Form.Group>
              </Col>
            </Row>
            {showWebcam && (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
                <Button onClick={capture} className="capturebtn">
                  Capture photo
                </Button>
              </div>
            )}
            {(selectedImage || capturedImage) && (
              <div>
                <img
                  src={selectedImage || capturedImage}
                  alt="Selected"
                  style={{ maxWidth: "15%" }}
                />
                <Button
                  className="removeBtn"
                  onClick={() => {
                    setSelectedImage(null);
                    setCapturedImage(null);
                    setCompressedImage(null);
                  }}
                >
                  Remove
                </Button>
              </div>
            )}
            <Modal.Footer>
              <Button
                className="closebtn"
                onClick={handleClose}
                disabled={loading}
              >
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="submitbtn"
                disabled={loading || !isFormValid}
              >
                Add Member
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {loading && (
        <div style={overlayStyle}>
          <CircularProgress />
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Changed duration to 3000ms (3 seconds)
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          className="alert1"
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          New Member added successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

export default AddMember;
