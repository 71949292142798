import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AttendanceCalendar from "./AttendanceCalendar";
import { getRenewalMessage } from "../../utils/utils";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function RenewPopup({ show, handleClose, member, attendanceData }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [renewDate, setRenewDate] = useState("");
  const [membershipPeriod, setMembershipPeriod] = useState("");
  const [amount, setAmount] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (member) {
      setRenewDate(member?.MembershipDetails["Renewal Date"] || "");
    }
  }, [member]);

  const handleCloseCalendar = () => setShowCalendar(false);

  const today = new Date().toISOString().split("T")[0];
  const renewalDate = member?.MembershipDetails["Renewal Date"];
  const renewalMessage = renewalDate
    ? getRenewalMessage(renewalDate)
    : { message: "", className: "" };
  const isRenewDateDisabled = renewalDate && renewalDate >= today;

  const handleSubmit = async () => {
    if (renewDate === "0000-00-00" || !membershipPeriod || !amount) {
      console.log("renewDateis" + renewDate);
      setSnackbarMessage("Please fill in all fields");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    const data = {
      MID: member.MID,
      renewalDate: renewDate || member.MembershipDetails["Renewal Date"],
      membershipPeriod,
      amount,
    };

    try {
      const response = await fetch(
        `https://thedynamicgym.in/api/updatemembership/${member.MID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setSnackbarMessage("Membership Updated Successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Reload after 3 seconds
      } else {
        setSnackbarMessage("Failed to Update membership");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error occurred while adding membership");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="RenewModals">
        <Modal.Header closeButton>
          <Modal.Title className="Mtitle">Renew Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Mbody">
          <Form className="Addmemberform">
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="Addname">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={member?.Name || ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="number"
                    value={member?.MID || ""}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Last Payment Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={member?.MembershipDetails["Payment Date"] || ""}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28 historydiv">
                <div>
                  <p className="historycls">History Calendar:</p>
                  <button
                    className="historyCalender"
                    onClick={() => setShowCalendar(true)}
                  >
                    <img src="Images/history.png" alt="History Calendar" />
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Renew Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={renewDate}
                    onChange={(e) => setRenewDate(e.target.value)}
                    disabled={isRenewDateDisabled}
                  />
                  <p className={renewalMessage.className}>
                    {renewalMessage.message}
                  </p>
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Membership Period</Form.Label>
                  <Form.Select
                    value={membershipPeriod}
                    onChange={(e) => setMembershipPeriod(e.target.value)}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {[...Array(12)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1} {i === 12 ? "Year" : "Month"}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Enter Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="closebtn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="submitbtn1" onClick={handleSubmit}>
            Renew Membership
          </Button>
        </Modal.Footer>
      </Modal>
      <AttendanceCalendar
        show={showCalendar}
        handleClose={handleCloseCalendar}
        attendanceData={attendanceData}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          className="alert1"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default RenewPopup;
