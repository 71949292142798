import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import AddMember from "./AddMember";

const Cards = ({ members }) => {
  const [showAddMember, setShowAddMember] = useState(false);
  const [activeMembers, setActiveMembers] = useState(0);
  const [renewalsToday, setRenewalsToday] = useState(0);
  const [presentToday, setPresentToday] = useState(0); // State to store the number of members present today

  const handleCloseAddMember = () => setShowAddMember(false);
  const handleShowAddMember = () => setShowAddMember(true);

  useEffect(() => {
    if (members && Array.isArray(members)) {
      setActiveMembers(members.length);

      const today = new Date();
      const todayRenewals = members.filter((member) => {
        const renewDate = new Date(member.MembershipDetails["Renewal Date"]);
        return (
          renewDate.getDate() === today.getDate() &&
          renewDate.getMonth() === today.getMonth() &&
          renewDate.getFullYear() === today.getFullYear()
        );
      }).length;
      setRenewalsToday(todayRenewals);
    }
  }, [members]);

  // Fetch the number of members present today
  useEffect(() => {
    const fetchPresentToday = async () => {
      try {
        const response = await fetch(
          "https://thedynamicgym.in/api/attendancelist"
        );
        const data = await response.json();
        setPresentToday(data); // Assuming the API returns a list of members present today
      } catch (error) {
        console.error("Error fetching present today data:", error);
      }
    };

    fetchPresentToday();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      <Row>
        <Col md={3} sm={12}>
          <div className="Cardinner">
            <Row>
              <Col md={8} sm={12} className="algndiv">
                <div className="algndiv2">
                  <img src="Images/card1.png" alt="" />
                  <p className="cardtext">Active Members</p>
                </div>
              </Col>
              <Col md={4} sm={12} className="algndiv">
                <div>
                  <p className="numnercls">{activeMembers}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3} sm={12}>
          <div className="Cardinner">
            <Row>
              <Col md={8} sm={12} className="algndiv">
                <div className="algndiv2">
                  <img src="Images/card2.png" alt="" />
                  <p className="cardtext">Present Today</p>
                </div>
              </Col>
              <Col md={4} sm={12} className="algndiv">
                <div>
                  <p className="numnercls">{presentToday}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3} sm={12}>
          <div className="Cardinner">
            <Row>
              <Col md={8} sm={12} className="algndiv">
                <div className="algndiv2">
                  <img src="Images/card3.png" alt="" />
                  <p className="cardtext">Renewals Today</p>
                </div>
              </Col>
              <Col md={4} sm={12} className="algndiv">
                <div>
                  <p className="numnercls">{renewalsToday}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3} sm={12}>
          <div className="Cardinner">
            <Row>
              <Col md={8} sm={12} className="algndiv">
                <div className="algndiv2">
                  <img src="Images/card4.png" alt="" />
                  <p className="cardtext">Add Member</p>
                </div>
              </Col>
              <Col md={4} sm={12} className="algndiv">
                <div>
                  <button className="addbtn" onClick={handleShowAddMember}>
                    <img src="Images/add.png" alt="" />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <AddMember show={showAddMember} handleClose={handleCloseAddMember} />
    </div>
  );
};

export default Cards;
