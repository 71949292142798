import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(""); // Clear error message when user starts typing
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.get(
        `https://thedynamicgym.in/api/logins/${password}`
      );
      if (response.data.status === "success") {
        const key = response.data.key;
        const expiryTime = Date.now() + 2 * 60 * 60 * 1000; // 2 hours
        localStorage.setItem("authKey", key);
        localStorage.setItem("authKeyExpiry", expiryTime);
        navigate("/home");
      } else {
        setError("Password incorrect. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="loginpagedesign">
      <div className="innerdivlog">
        <div className="imgsetts">
          <img src="Images/loglogo.png" alt="" />
        </div>
        <h2>Login</h2>
        <div>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter password"
            onKeyPress={handleKeyPress}
          />
          <div>
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
}

export default Login;
