import React, { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import SearchBar from "./SearchBar";
import Filters from "./Filters";
import RenewPopup from "./RenewPopup";
import EditProfile from "./EditProfile";
import AttendanceCalendar from "./AttendanceCalendar"; // Make sure to create this component
import { getRenewalMessage } from "../../utils/utils";

function Table({ members }) {
  const [showAddMember, setShowAddMember] = useState(false);
  const [showEditMember, setShowEditMember] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [editMemberData, setEditMemberData] = useState(null);

  const handleCloseAddMember = () => setShowAddMember(false);
  const handleShowAddMember = (member, attendance) => {
    setCurrentMember(member);
    setAttendanceData(attendance);
    setShowAddMember(true);
  };
  const handleCloseEditMember = () => setShowEditMember(false);
  const handleShowEditMember = async (MID) => {
    const data = await fetchMemberDetailsForEdit(MID);
    setEditMemberData(data);
    setShowEditMember(true);
  };
  const handleCloseCalendar = () => setShowCalendar(false);

  const formatDate = (dateString) => {
    if (dateString === "0000-00-00") {
      return "No Date Present";
    } else {
      console.log("date is here abi" + dateString);
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  };

  const fetchAttendance = async (MID) => {
    try {
      const response = await fetch(
        `https://thedynamicgym.in/api/attendancelist/${MID}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      return [];
    }
  };

  const fetchMemberDetails = async (MID) => {
    try {
      const response = await fetch(
        `https://thedynamicgym.in/api/members/${MID}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching member details:", error);
      return null;
    }
  };

  const fetchMemberDetailsForEdit = async (MID) => {
    try {
      const response = await fetch(
        `https://thedynamicgym.in/api/Editprofile/${MID}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching member details for edit:", error);
      return null;
    }
  };

  const handleFetchAndShowCalendar = async (MID) => {
    const data = await fetchAttendance(MID);
    setAttendanceData(data);
    setShowCalendar(true);
  };

  const calculateAbsentDays = (attendanceRecords) => {
    if (!attendanceRecords || attendanceRecords.length === 0) {
      return null;
    }
    const latestPresentDate = new Date(
      Math.max(...attendanceRecords.map((record) => new Date(record.Date)))
    );
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const diffTime = yesterday - latestPresentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 1) {
      return null; // Present today, no absent message
    }
    if (diffDays > 8) {
      return "Absent - 8+ days";
    }
    return `Absent - ${diffDays - 1} days`;
  };

  const sortedMembers = [...members].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const filteredMembers = sortedMembers.filter((member) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch =
      member.MID.toString().includes(searchTerm) ||
      member.Name.toLowerCase().includes(searchLower);
    // member.Number.toString().includes(searchTerm);

    if (!matchesSearch) return false;

    if (!selectedFilter) return true;

    const today = new Date();
    const renewDate = new Date(member.MembershipDetails["Renewal Date"]);
    const diffTime = renewDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

    if (selectedFilter === "renewToday" && diffDays === 0) return true;
    if (selectedFilter === "renewIn5Days" && diffDays > 0 && diffDays <= 5)
      return true;
    if (selectedFilter === "renewIn10Days" && diffDays > 0 && diffDays <= 10)
      return true;
    if (selectedFilter === "expired" && diffDays < 0) return true;

    // New filters for absent days
    if (selectedFilter === "absent5days") {
      const absentDays = calculateAbsentDays(member.AttendanceRecords);
      return absentDays && parseInt(absentDays.match(/\d+/)[0], 10) <= 4;
    }

    if (selectedFilter === "absent10days") {
      const absentDays = calculateAbsentDays(member.AttendanceRecords);
      return absentDays && parseInt(absentDays.match(/\d+/)[0], 10) <= 8;
    }

    return false;
  });

  if (!members || members.length === 0) {
    return <Alert variant="warning">No members available</Alert>;
  }

  return (
    <div>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </Col>
          <Col md={{ span: 2, offset: 4 }} sm={12}>
            <Filters
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          </Col>
        </Row>
      </div>

      <div className="tableclsdiv MT20">
        <table className="tablecls">
          <thead>
            <tr className="trcls">
              <th>ID</th>
              <th>Name</th>
              <th>Number</th>
              <th>Renewal Date</th>
              <th>Renew</th>
              <th className="textalgncenter">Attendance</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.length > 0 ? (
              filteredMembers.map((member, index) => (
                <tr key={member.MID}>
                  <td>{member.MID}</td>
                  <td>{member.Name}</td>
                  <td>{member.Number}</td>
                  <td>
                    {formatDate(member.MembershipDetails["Renewal Date"])}
                    <br />
                    <span
                      className={
                        getRenewalMessage(
                          member.MembershipDetails["Renewal Date"]
                        ).className
                      }
                    >
                      {
                        getRenewalMessage(
                          member.MembershipDetails["Renewal Date"]
                        ).message
                      }
                    </span>
                  </td>
                  <td>
                    <button
                      className="Rbtn"
                      onClick={async () => {
                        const attendance = await fetchAttendance(member.MID);
                        const memberDetails = await fetchMemberDetails(
                          member.MID
                        );
                        handleShowAddMember(memberDetails, attendance);
                      }}
                    >
                      Renew
                    </button>
                  </td>
                  <td className="textalgncenter">
                    <button
                      className="iconbtns"
                      onClick={() => handleFetchAndShowCalendar(member.MID)}
                    >
                      <img src="Images/date.png" alt="Attendance" />
                      <p className="absentcls">
                        {calculateAbsentDays(member.AttendanceRecords) &&
                          calculateAbsentDays(member.AttendanceRecords)}
                      </p>
                    </button>
                  </td>
                  <td>
                    <button
                      className="iconbtns"
                      onClick={() => handleShowEditMember(member.MID)}
                    >
                      <img src="Images/edit.png" alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No members found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RenewPopup
        show={showAddMember}
        handleClose={handleCloseAddMember}
        member={currentMember}
        attendanceData={attendanceData}
      />
      <EditProfile
        show={showEditMember}
        handleClose={handleCloseEditMember}
        memberData={editMemberData}
      />
      <AttendanceCalendar
        show={showCalendar}
        handleClose={handleCloseCalendar}
        attendanceData={attendanceData}
      />
    </div>
  );
}

export default Table;
