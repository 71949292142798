import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "../../CSS/CustomCSS.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"; // Import the specific icon

function SearchBar({ searchTerm, setSearchTerm }) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <InputGroup className="searchbar1">
        <Form.Control
          placeholder="Search members..."
          aria-label="Search"
          value={searchTerm}
          className="Searchinput1"
          onChange={handleSearchChange}
        />
        <Button className="searchbtn">
          <FontAwesomeIcon icon={faSearch} /> {/* Use the imported icon */}
        </Button>
      </InputGroup>
    </div>
  );
}

export default SearchBar;
