import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../Pages/Home/Dashboard";
import Admin from "../Pages/Admin Page";
import Login from "../Pages/Login Page/Login";
import Revenue from "../Pages/Revenue";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<Dashboard />} />
        <Route path="/adminpage" element={<Admin />} />
        <Route path="/revenue" element={<Revenue />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
