import React from "react";
import Form from "react-bootstrap/Form";

function Filters({ selectedFilter, setSelectedFilter }) {
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <div>
      <Form.Select
        aria-label="Default select example"
        value={selectedFilter}
        onChange={handleFilterChange}
        className="filters"
      >
        <option value="">All</option>
        <option value="renewToday">Renew Today</option>
        <option value="renewIn5Days">Renew in 5 Days</option>
        <option value="renewIn10Days">Renew in 10 Days</option>
        <option value="expired">Expired</option>
        <option value="absent5days">Absent for 4 days</option>
        <option value="absent10days">Absent for 8 days</option>
      </Form.Select>
    </div>
  );
}

export default Filters;
