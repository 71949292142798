import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "../../CSS/CustomCSS.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"; // Import the specific icon

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm.trim());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div>
      <InputGroup className="searchbar">
        <Form.Control
          ref={inputRef}
          placeholder="Type your ID..."
          aria-label="Search"
          value={searchTerm}
          className="Searchinput"
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown} // Add onKeyDown event handler
        />
        <Button className="searchbtn" onClick={handleSearchClick}>
          <FontAwesomeIcon icon={faSearch} /> {/* Use the imported icon */}
        </Button>
      </InputGroup>
    </div>
  );
}

export default SearchBar;
