import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Stack, CircularProgress, Snackbar, Alert } from "@mui/material";
import Webcam from "react-webcam";
import Compressor from "compressorjs";
import axios from "axios";

function EditProfile({ show, handleClose, memberData }) {
  const [member, setMember] = useState({
    MID: "",
    Name: "",
    Number: "",
    "Date of Join": "",
    ImagePath: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const webcamRef = useRef(null);

  useEffect(() => {
    if (memberData) {
      const baseURL = "https://thedynamicgym.in";
      setMember({
        MID: memberData.MID,
        Name: memberData.Name,
        Number: memberData.Number,
        "Date of Join": memberData["Date of Join"],
        ImagePath: memberData.ImagePath
          ? `${baseURL}/${memberData.ImagePath}`
          : "Images/user.png",
      });
      // Reset image states when memberData changes
      setSelectedImage(null);
      setCompressedImage(null);
      setCapturedImage(null);
    }
  }, [memberData]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.6, // Compression quality, 0 to 1
        success: (compressedResult) => {
          setCompressedImage(compressedResult);
          setSelectedImage(URL.createObjectURL(compressedResult));
          setCapturedImage(null);
        },
        error(err) {
          console.error("Compression failed:", err);
        },
      });
    } else {
      setSelectedImage(null);
    }
    setShowWebcam(false);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setSelectedImage(null);
    setCompressedImage(null);
    setShowWebcam(false);
  }, [webcamRef]);

  const handleNumberChange = (event) => {
    let value = event.target.value;
    value = value.slice(0, 10); // Restrict to 10 digits
    setMember({ ...member, Number: value });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("MID", member.MID);
    formData.append("Name", member.Name);
    formData.append("Number", member.Number);
    formData.append("DateofJoin", member["Date of Join"]);

    if (compressedImage) {
      formData.append("Image", compressedImage);
    } else if (capturedImage) {
      const blob = await (await fetch(capturedImage)).blob();
      formData.append("Image", blob);
    }

    try {
      await axios.post(
        `https://thedynamicgym.in/api/Editprofile/${member.MID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSnackbarOpen(true);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      handleClose();
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div style={overlayStyle}>
          <CircularProgress size={80} />
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="RenewModals">
        <Modal.Header closeButton>
          <Modal.Title className="Mtitle">Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Mbody">
          <Form className="Addmemberform">
            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="Addname">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={member.Name}
                    onChange={(e) =>
                      setMember({ ...member, Name: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={member.Number}
                    onChange={handleNumberChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={12} className="MB28">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    value={member["Date of Join"]}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12} className="MB28">
                <Form.Group>
                  <Form.Label>Profile Picture</Form.Label>
                  <Stack direction="row" spacing={4}>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    <Button
                      variant="secondary"
                      className="Cambtn"
                      onClick={() => setShowWebcam(true)}
                    >
                      <img src="Images/cam.png" alt="Camera Icon" />
                    </Button>
                  </Stack>
                </Form.Group>
              </Col>
            </Row>
            {showWebcam && (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
                <Button onClick={capture} className="capturebtn">
                  Capture photo
                </Button>
              </div>
            )}
            <Row>
              <div>
                <img
                  src={
                    selectedImage ||
                    capturedImage ||
                    `${member.ImagePath}?${new Date().getTime()}`
                  }
                  alt="Profile"
                  className="profilepic"
                  style={{ maxWidth: "15%" }}
                />
                {(selectedImage || capturedImage) && (
                  <Row className="divimgcls">
                    <Button
                      className=" removeimgsyle"
                      onClick={() => {
                        setSelectedImage(null);
                        setCapturedImage(null);
                        setCompressedImage(null);
                      }}
                    >
                      <img src="Images/close.png" alt="Camera Icon" />
                    </Button>
                  </Row>
                )}
              </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="closebtn" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            className="submitbtn1"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message="Updated Successfully!"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert className="alert1" severity="success" sx={{ width: "100%" }}>
          UserProfile Updated successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

export default EditProfile;
