import React from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Cards from "./Cards";
import Table from "./Table";
const RightComponent = ({ members }) => {
  return (
    <div>
      <div className="Maininner">
        <div className="BW">
          <Header />
        </div>
        <Container className="P22 W100">
          <div>
            <Cards members={members} /> {/* Pass members to Cards */}
          </div>
          <div className="MT50">
            <Table members={members} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default RightComponent;
