import React from "react";
import { Col, Row } from "react-bootstrap";
const Header = () => {
  return (
    <div>
      <Row className="M0">
        <Col xs={6} sm={6} md={6}>
          <p className="Usercls M0">
            Hello, <b>Deekshit</b>
          </p>
        </Col>
        <Col xs={6} sm={6} md={6}>
          <div className="homebtnstyle">
            <a href="/#/home">Home</a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
