import React from "react";
import { Modal, Button } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const AttendanceCalendar = ({ show, handleClose, attendanceData }) => {
  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const attendance = attendanceData.find(
        (entry) => new Date(entry.Date).toDateString() === date.toDateString()
      );

      if (attendance) {
        return (
          <span
            style={{
              height: "10px",
              width: "10px",

              borderRadius: "50%",
              display: "inline-block",
            }}
            className="calenderspan"
          ></span>
        );
      }
    }
    return null;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="calenderheding">
          Attendance Calendar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar tileContent={tileContent} className={"calenderstyle"} />
      </Modal.Body>
    </Modal>
  );
};

export default AttendanceCalendar;
